import * as React from "react"
import Layout from "../../components/layout"
import WideImage from "../../components/WideImage"
import { Link } from "gatsby"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import Seo from "../../components/seo";
import Navbar from "../../components/navbar";
import ProductImage from "../../components/ProductImage"


const ProductsPage = () => (

  <Layout className="page platform-page">


    <Seo title={"Platform"} />

      <section className="py-3 container extra-header">
        <div className="row py-lg-3">
          <div className="col-lg-8 col-md-8 mx-auto">
            <h1 className="fw-light">Huey Platform</h1>
            <p className="lead">
              Huey is a real-time platform for improving energy and water efficiency in households.
            </p>
          </div>
        </div>
      </section>

      <div className="album py-5 bg-light">
        <div className="container">
          <div className="row row-cols-1 row-cols-md-2 g-3">

            <div className="col">
              <a href="/platform/huey-mobile" className="product-card card shadow link-unstyled" role="button">
                <StaticImage
                  layout="fullWidth"
                  aspectRatio={3 / 2}
                  alt=""
                  src="../../images/huey-mobile-43-800.jpeg"
                  //formats={["auto", "webp", "avif"]}
                />
                <div className="title-band">
                  <h5 className="card-title fw-bold">Huey Mobile</h5>
                  <h6 className="card-subtitle mb-2">Mobile App</h6>
                </div>
                <div className="card-body">
                  <p className="card-text">
                    Graph display of live, daily and weekly data. Notifications for higher than expected values.
                  </p>
                  <div className="d-flex justify-content-between align-items-center">
                    <small className="text-muted">In Trial for Release in early 2022</small>
                  </div>
                </div>
              </a>
            </div>

            <div className="col">
              <div className="product-card card" >
                <div className="ratio ratio-3x2" style={{width: '100%', padding: 0, color: 'lightblue'}}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="currentColor" className="bi bi-cloud-fill" viewBox="0 0 16 16">
                    <path
                      d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"/>
                  </svg>
                </div>
                <div className="title-band">
                  <h5 className="card-title fw-bold">Huey Cloud</h5>
                  <h6 className="card-subtitle mb-2">Real-time Streaming Data Server</h6>
                </div>
                <div className="card-body">
                  <p className="card-text">
                    Provides real-time integration, synchronisation and analysis of data channels, and generates events.
                  </p>
                  <div className="d-flex justify-content-between align-items-center">
                    <small className="text-muted">In Trial for launch in early 2022</small>
                  </div>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="product-card card">
                <div className="ratio ratio-3x2" style={{width: '100%', padding: 0, color: 'darkslategrey'}}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="currentColor" className="bi bi-cloud-fill" viewBox="0 0 16 16">
                    <path
                      d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"/>
                  </svg>
                </div>
                <div className="title-band">
                  <h5 className="card-title fw-bold">Huey CloudView</h5>
                  <h6 className="card-subtitle mb-2">Supervisor Console</h6>
                </div>
                <div className="card-body">
                  <p className="card-text">
                    Provides web-based overview for utilities, authorities and research
                  </p>
                  <div className="d-flex justify-content-between align-items-center">
                    <small className="text-muted">Mid 2022</small>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
  </Layout>
)

export default ProductsPage
